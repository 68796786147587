
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { models } from '@/views/datatables/resources'

@Component({
  components: {
    DefaultDialog: () => import(/* webpackChunkName: "default-dialog" */'@/components/core/ModelDialog.vue'),
    Lead: () => import('@/views/forms/custom/LeadForm.vue'),
  },
})
  export default class LinkedModel extends Vue {
  @Prop({ type: String, required: true }) name!: string
  @Prop({ type: Number }) id?: string

  get component () {
    const { name, id } = this
    const model = models[name]

    return {
      name: model ? name : 'default-dialog',
      props: {
        id,
        model: name,
      },
    }
  }
  }
